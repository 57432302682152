<template>
  <v-main class="font">
    <h1>Selamat Datang di Dashboard AtmaRewards</h1> 
    AtmaRewards merupakan sebuah sistem
    yang dikembangkan untuk meningkatkan penggunaan layanan TIK di Atma Jogja,
    khususnya layanan learning management system (situs kuliah). Layanan ini
    akan memberikan penghargaan berupa poin berdasarkan aktivitas pengguna di
    situs kuliah, baik dosen maupun mahasiswa. Poin yang dikumpulkan dapat
    ditukarkan dengan berbagai hadiah yang telah disediakan.
  </v-main>
</template>

<script>
export default {
  mounted() {
    this.loadingCursor();
  },
  methods: {
    loadingCursor() {
      // When the window has finished loading, set it back to default...
      document.body.style.cursor = "default";
    },
  },
};
</script>